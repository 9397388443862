export const teamEditLoader = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SAVE_LOADER_TEAM_EDIT":
      return true
    case "HIDE_SAVE_LOADER_TEAM_EDIT":
      return false
    default:
      return state
  }
}
