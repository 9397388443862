export const approvalLoad = (state = false, action) => {
  switch (action.type) {
    case "SHOW_APPROVAL_LOADER_ADMIN":
      return true
    case "HIDE_APPROVAL_LOADER_ADMIN":
      return false
    default:
      return state
  }
}
