export const adminMemberEdit = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SINGLE_MEMBER_ADMIN_EDIT_LOADER":
      return true
    case "HIDE_SINGLE_MEMBER_ADMIN_EDIT_LOADER":
      return false
    default:
      return state
  }
}
