const initialState = {
  teamLeader: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: "",
    raftingDay: "",
    raftingTime: "",
    targetAmount: "",
    teamName: "",
    organisation: "",
    shirtSize: "S",
    transport: "None"
  },
  teamMembers: [
    {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      shirtSize: "S",
      transport: "None",
      id: 1
    },
    {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      shirtSize: "S",
      transport: "None",
      id: 2
    },
    {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      shirtSize: "S",
      transport: "None",
      id: 3
    },
    {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      shirtSize: "S",
      transport: "None",
      id: 4
    }
  ]
}

export const regFormReducer = (state = initialState, action) => {
  let newTeamLeader,
    newTeamMembers,
    newMemberIndex,
    newState,
    currentTeamMembers
  switch (action.type) {
    case "TEAM_LEADER_FIRST_NAME":
      newTeamLeader = { ...state.teamLeader, firstName: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_LAST_NAME":
      newTeamLeader = { ...state.teamLeader, lastName: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_MOBILE_NUMBER":
      newTeamLeader = { ...state.teamLeader, mobileNumber: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_TARGET_AMOUNT":
      newTeamLeader = { ...state.teamLeader, targetAmount: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_TEAM_NAME":
      newTeamLeader = { ...state.teamLeader, teamName: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_ORGANISATION":
      newTeamLeader = { ...state.teamLeader, organisation: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_EMAIL_ADDRESS":
      newTeamLeader = { ...state.teamLeader, emailAddress: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_RAFTING_DAY_AND_TIME":
      newTeamLeader = {
        ...state.teamLeader,
        raftingDay: action.day,
        raftingTime: action.time
      }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_SHIRT_SIZE":
      newTeamLeader = { ...state.teamLeader, shirtSize: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case "TEAM_LEADER_TRANSPORT":
      newTeamLeader = { ...state.teamLeader, transport: action.payload }
      return { ...state, teamLeader: newTeamLeader }
    case `TEAM_MEMBER_${action.index}_FIRST_NAME`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        firstName: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_LAST_NAME`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        lastName: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_MOBILE_NUMBER`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        mobileNumber: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_EMAIL_ADDRESS`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        emailAddress: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_SHIRT_SIZE`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        shirtSize: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_TRANSPORT`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        ...state.teamMembers[action.index - 1],
        transport: action.payload
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    case `TEAM_MEMBER_${action.index}_DISCARD`:
      currentTeamMembers = state.teamMembers.filter(
        (item, index) => item.id !== action.index
      )
      newTeamMembers = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        mobileNumber: "",
        shirtSize: "S",
        transport: "None",
        id: action.index
      }
      currentTeamMembers.splice(action.index - 1, 0, newTeamMembers)
      return { ...state, teamMembers: currentTeamMembers }
    default:
      return state
  }
}
