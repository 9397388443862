export const registerLoad = (state = false, action) => {
  switch (action.type) {
    case "SHOW_REGISTER_BUTTON_LOADER":
      return true
    case "HIDE_REGISTER_BUTTON_LOADER":
      return false
    default:
      return state
  }
}
