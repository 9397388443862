// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-team-js": () => import("./../../../src/pages/createTeam.js" /* webpackChunkName: "component---src-pages-create-team-js" */),
  "component---src-pages-fundraising-js": () => import("./../../../src/pages/fundraising.js" /* webpackChunkName: "component---src-pages-fundraising-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-new-js": () => import("./../../../src/pages/register-new.js" /* webpackChunkName: "component---src-pages-register-new-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

