import React from "react"
import { createStore, combineReducers } from "redux"
import { Provider } from "react-redux"
import * as reducers from "./src/reducers"
import { window } from "browser-monads"

const combinedReducers = combineReducers({
  user: reducers.setUser,
  isTeamEditLoaderShowing: reducers.teamEditLoader,
  isAdminDeleteTeamLoaderShowing: reducers.adminDeleteLoader,
  isEmailLoaderShowing: reducers.emailSendingLoader,
  registerForm: reducers.regFormReducer,
  regMemberCount: reducers.regFormMemberCount,
  isRegisterLoaderShowing: reducers.registerLoad,
  isApprovalLoaderShowing: reducers.approvalLoad,
  isDeclineLoaderShowing: reducers.declineLoad,
  isTeamMemberEditLoaderShowing: reducers.teamMemberEdit,
  isAdminGeneralEditLoaderShowing: reducers.adminGeneralEdit,
  isAdminMemberEditLoaderShowing: reducers.adminMemberEdit
})

export default ({ element }) => {
  const store = createStore(
    combinedReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  return <Provider store={store}>{element}</Provider>
}
