export const declineLoad = (state = false, action) => {
  switch (action.type) {
    case "SHOW_DECLINE_LOADER_ADMIN":
      return true
    case "HIDE_DECLINE_LOADER_ADMIN":
      return false
    default:
      return state
  }
}
