export const regFormMemberCount = (state = 1, action) => {
  switch (action.type) {
    case "INCREMENT_MEMBER_COUNT_REGISTER_FORM":
      if (state < 5) {
        return state + 1
      } else {
        return state
      }
    case "DECREMENT_MEMBER_COUNT_REGISTER_FORM":
      if (state > 1) {
        return state - 1
      } else {
        return state
      }
    case "SET_EXPLICIT_COUNT_REGISTER_FORM":
      return action.payload
    default:
      return state
  }
}
