export const adminGeneralEdit = (state = false, action) => {
  switch (action.type) {
    case "SHOW_GENERAL_EDIT_LOADER_ADMIN":
      return true
    case "HIDE_GENERAL_EDIT_LOADER_ADMIN":
      return false
    default:
      return state
  }
}
